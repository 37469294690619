<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
    content: {
        type: Object,
        required: true,
    }
})

const loaded = ref(false)
const community = ref();
const showChannel = ref(false);
const activeChannel = ref(null);
const userIsLoggedIn = ref(false);

const loadCommunity = async () => {
    if (!props.content.communityId) {
        loaded.value = true
        return;
    }
    try {
        const res = await axios.get(route('community.member.getCommunity', props.content.communityId));
        community.value = res.data.community
    } catch (e) {
        loaded.value = true
    }
}

const goToChannel = (channel) => {
    activeChannel.value = channel;
    showChannel.value = true;
}

const availableChannels = computed(() => {
    if (!community.value) {
        return [];
    }

    if (props.content.showAllChannels) {
        return community.value.channels.filter(channel => ! channel.archived);
    } else {
        return community.value.channels.filter(channel => props.content.selectedChannels.includes(channel.id) && ! channel.archived);
    }

})

onMounted(() => {
    loadCommunity();

    userIsLoggedIn.value = window.myflow.user.loggedIn;
})

</script>

<template>
    <div>
        <div v-if="userIsLoggedIn">
            <div
                v-if="community"
                class="pb-8 text-black"
            >
                <list-basic>
                    <template #header>
                        <h5>{{ community.name }}</h5>
                    </template>
                    <list-basic-item
                        v-for="channel in availableChannels"
                        :key="channel.id"
                    >
                        <template #title>
                            # {{ channel.name }}
                        </template>

                        <template #actions>
                            <button-base
                                size="sm"
                                v-on:click="goToChannel(channel)"
                                class="relative"
                            >
                                <span>
                                    View
                                </span>
                            </button-base>
                        </template>
                    </list-basic-item>
                    <list-basic-item
                        v-if="availableChannels.length === 0"
                    >
                        <template #title>
                            <span class="text-gray-400 italic">There are no channels to show here</span>
                        </template>
                    </list-basic-item>
                </list-basic>

                <slideout-with-slots
                    max-width-class="max-w-[80vw] md:max-w-screen"
                    v-if="showChannel"
                    @close="showChannel = false"
                >
                    <template #title>
                        <h2>{{ activeChannel.name }}</h2>
                    </template>
                    <template #body>
                        <community-channel
                            :channel-id="activeChannel.id"
                            :channel="activeChannel"
                        ></community-channel>
                    </template>
                </slideout-with-slots>

            </div>

            <div
                v-if="! loaded && ! community"
                class="flex justify-center py-12"
            >
                <mf-spinner-medium class="w-24 h-12" />
            </div>
            
            <p
                v-if="loaded && ! community"
                class="text-center py-12"
            >
                {{ _mft('community:communities.notMemberOfThisCommunity') }}
            </p>
        </div>
    </div>
</template>

<style scoped>

</style>
